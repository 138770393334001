<template>
  <PageLayout :headerWithBG="true">
    <div class="home">
      <div
        class="home__landing-img flex flex__center flex__item-center flex__column mb-8"
      >
        <h1 class="text__white fs-large mb-1 text-center stagger__in--1">
          Aenean euismod bibendum laoreet
        </h1>
        <p class="text-center text__white fs-large mb-4 stagger__in--2">
          Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus
          accumsan et <br />
          viverra justo commodo. Proin sodales pulvinar sic tempor.
        </p>

        <div class="home__filter mb-3 u-above stagger__in--3">
          <div
            class="home__filter-inner-bg flex flex__spaced flex__item-center"
          >
            <search-input
              v-model="searchValue"
              placeholder="Job Title or Keywords"
              class="home__filter-search"
            />
            <div class="home__filter-separator" />
            <Dropdown
              class="home__filter-dropdown"
              :options="jobCategories"
              @selected="updateSelected"
              placeholder="Select Job Category"
            />
            <div class="home__filter-separator" />
            <Dropdown
              class="home__filter-dropdown"
              placeholder="Choose Location"
              :options="locations"
            />
            <div class="mr-1 m-d-none" />
            <div class="home__m-btn-separator" />
            <Button class="home__filter-btn" theme="purple">Search</Button>
          </div>
        </div>

        <div class="flex flex__space-center stagger__in--4 home__past-searches">
          <span class="text__white mr-2 text">Past Searches</span>
          <div class="flex home__tabs">
            <div class="home__tab mr-1">
              <span class="fs-14 mr-1 text__white">Business analyst</span>
              <span class="text__light-green fs-14">(07 new)</span>
            </div>
            <div class="home__tab mr-1">
              <span class="fs-14 mr-1 text__white">Product Manager</span>
              <span class="text__light-green fs-14">(25 new)</span>
            </div>
            <div class="home__tab">
              <span class="fs-14 mr-1 text__white">Sales Executive</span>
              <span class="text__light-green fs-14">(45 new)</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex__center mb-6">
      <Tab
        class="scroll-reveal"
        @selected="tabSelected = $event.name"
        :options="[{ name: 'Recent Jobs' }, { name: 'Featured Jobs' }]"
      />
    </div>

    <div
      class="home__jobs-wrapper grid grid__layout grid__mobile gap-3 m-gap-2 no-col-gap"
    >
      <div v-for="(n, i) in 9" :key="i" class="col-4 m-col-12 scroll-reveal">
        <InfoCard>
          <div class="info__card-top flex flex__item-center pb-2 bb-1 mb-2">
            <div class="mr-2" style="flex-basis: 60px">
              <img
                style="width: 100%"
                src="../../assets/icons/dell-logo.svg"
                alt="dell-logo"
              />
            </div>
            <div
              style="flex-basis: calc(100% - 60px)"
              class="flex flex__grow flex__shrink-1 flex__column"
            >
              <p class="text-bold mb-1">Front End Developer</p>
              <div style="padding-bottom: 5px">
                <span class="skills__tab">Web Development</span>
                <span class="skills__tab">Angular</span>
              </div>
            </div>
          </div>

          <div class="flex flex__spaced">
            <span style="color: #8b90a0" class="fs-14">Posted 2 days ago</span>
            <button
              @click="
                $router.push({
                  name: 'job',
                  params: {
                    profession: 'Front-end Developer'
                  }
                })
              "
              class="flex flex__item-center cursor-pointer"
            >
              <span class="fs-14 cursor-pointer">View details</span>&nbsp;
              <div class="flex flex__item-center">
                <img
                  src="../../assets/icons/right-arrow.svg"
                  alt="right-arrow"
                />
              </div>
            </button>
          </div>
        </InfoCard>
      </div>
    </div>

    <div class="flex flex__center home__more-btn scroll-reveal">
      <Button @click="$router.push({ name: 'jobs' })" size="sm">
        View more
      </Button>
    </div>

    <div class="home__hiring-wrapper">
      <h1 class="text-center text-blue mb-6 fs-large fs-large-sm scroll-reveal">
        Top Hiring Companies
      </h1>
      <div class="grid grid__layout grid__mobile gap-2 mo-col-gap">
        <div class="col-3 m-col-12 scroll-reveal">
          <InfoCard type="raised">
            <div
              class="home__hiring-icons-wrap flex flex__column flex__item-center"
            >
              <div class="home__hiring-icons">
                <img
                  src="../../assets/icons/google-icon-blue.svg"
                  alt="google-icon"
                />
              </div>
              <h1>Google Inc.</h1>
            </div>
            <div class="flex flex__center">
              <span class="status__tab">12 new jobs</span>
            </div>
            <button class="home__hiring-action">Follow</button>
          </InfoCard>
        </div>
        <div class="col-3 m-col-12 scroll-reveal">
          <InfoCard type="raised">
            <div
              class="home__hiring-icons-wrap flex flex__column flex__item-center"
            >
              <div class="home__hiring-icons">
                <img src="../../assets/icons/atat-icon.svg" alt="atat-icon" />
              </div>
              <h1>AT&T Inc.</h1>
            </div>
            <div class="flex flex__center">
              <span class="status__tab text-center">12 new jobs</span>
            </div>
            <button class="home__hiring-action">Follow</button>
          </InfoCard>
        </div>
        <div class="col-3 m-col-12 scroll-reveal">
          <InfoCard type="raised">
            <div
              class="home__hiring-icons-wrap flex flex__column flex__item-center"
            >
              <div class="home__hiring-icons">
                <img src="../../assets/icons/dell-logo.svg" alt="dell-icon" />
              </div>
              <h1>Dell</h1>
            </div>
            <div class="flex flex__center">
              <span class="status__tab text-center">12 new jobs</span>
            </div>
            <button class="home__hiring-action">Follow</button>
          </InfoCard>
        </div>
        <div class="col-3 m-col-12 scroll-reveal">
          <InfoCard type="raised">
            <div
              class="home__hiring-icons-wrap flex flex__column flex__item-center"
            >
              <div class="home__hiring-icons">
                <img
                  src="../../assets/icons/samsung-icon.svg"
                  alt="samsung-icon"
                />
              </div>
              <h1>Samsung</h1>
            </div>
            <div class="flex flex__center">
              <span class="status__tab text-center">12 new jobs</span>
            </div>
            <button class="home__hiring-action">Follow</button>
          </InfoCard>
        </div>
      </div>
    </div>

    <div class="home__talent flex flex__center flex__item-center flex__column">
      <span class="skills__tab mb-3 tab-blue scroll-reveal"> Talent </span>
      <h1 class="fs-large mb-3 text-center text__black fw-700 scroll-reveal">
        Want Opportunities?
      </h1>
      <div class="w-23 scroll-reveal">
        <Button @click="$router.push({ name: 'login' })" theme="blue">
          Get Started Now
        </Button>
      </div>
    </div>
  </PageLayout>
</template>

<script>
import Header from "../../components/Header";
import SearchInput from "../../components/SearchInput";
import Button from "../../components/Button";
import InfoCard from "../../components/InfoCard";
import Footer from "../../components/Footer";
import Tab from "../../components/Tab";
import PageLayout from "../../layouts/PageLayout";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    Header,
    SearchInput,
    Button,
    InfoCard,
    Footer,
    Tab,
    PageLayout
  },
  data: () => ({
    searchValue: "",
    fruit: "",
    jobCategories: [
      { name: "Business Analyst", value: "business_analyst" },
      { name: "Engineering", value: "engineering" }
    ],
    locations: [
      { name: "Nigeria", value: "nigeria" },
      { name: "Ghana", value: "ghana" },
      { name: "Spain", value: "spain" },
      { name: "Italy", value: "italy" },
      { name: "Senegal", value: "senegal" }
    ],
    tabSelected: ""
  }),
  computed: {
    ...mapGetters({
      loggedUser: "auth/getLoggedUser",
      loggedIn: "auth/getLoggedIn"
    })
  },
  mounted() {
    const scrollingEls = document.querySelectorAll(".scroll-reveal");
    setTimeout(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            entry.target.classList.toggle("show", entry.isIntersecting);
            if (entry.isIntersecting) observer.unobserve(entry.target);
          });
        },
        {
          threshold: 0.4
        }
      );
      scrollingEls.forEach((el) => {
        observer.observe(el);
      });
    }, 800);
  },
  methods: {
    updateSelected() {}
  },
  watch: {
    searchValue() {}
  }
};
</script>

<style scoped></style>
